import request from './request'

const api = {
	// 获取列表
	lists: '/user/User/list',
	// 删除
	dele: '/user/User/delete',
}
export {
	request,
	api
}
